import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Corporate Signup Controller connected")
    this.element.addEventListener("submit", this.handleSubmit.bind(this))
  }

  async handleSubmit(event) {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(this.element);
    const response = await fetch(this.element.action, {
      method: "POST",
      body: formData,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Accept": "application/json"
      }
    });

    const data = await response.json();

    if (response.ok) {
      this.showNotification(data.message, "success");
      this.displaySuccessContent(data.message); // Pass the success message to displaySuccessContent
    } else {
      this.showNotification(data.message || "There was an error with the sign-up form. Please try again.", "error");
    }
  }

  showNotification(message, type) {
    const notificationArea = document.getElementById("notification_area");
    notificationArea.innerHTML = `
      <div role="alert" class="${type === "success" ? "text-green-700" : "text-red-700"}">
        <p class="section-text">${message}</p>
      </div>
    `;
  }

  displaySuccessContent(successMessage) {
    // Update the form's inner HTML to show the success content
    this.element.innerHTML = `
      <div role="alert">
        <p class="section-text text-green-700">${successMessage}</p>
        <p class="section-text">
          Thank you for joining NowCollab! We appreciate your interest and will review your information shortly. 
          Our team will reach out to you within 3 business days with the next steps.
        </p>
        <p class="section-text">
          While you wait, feel free to connect with us on social media or check out some useful links.
        </p>
        <div class="flex justify-between mt-8">
        <ul class="social-list flex space-x-4">
          <li><a href="#" class="social-link"><ion-icon name="logo-facebook"></ion-icon></a></li>
          <li><a href="#" class="social-link"><ion-icon name="logo-twitter"></ion-icon></a></li>
          <li><a href="#" class="social-link"><ion-icon name="logo-linkedin"></ion-icon></a></li>
          <li><a href="#" class="social-link"><ion-icon name="logo-instagram"></ion-icon></a></li>
        </ul>
        <ul class="quicklink-list space-y-2 mt-4">
          <li><a href="#" class="quicklink">Privacy Policy</a></li>
          <li><a href="#" class="quicklink">Terms of Service</a></li>
          <li><a href="#" class="quicklink">About Us</a></li>
          <li><a href="#" class="quicklink">Contact Us</a></li>
          <li><a href="#" class="quicklink">Careers</a></li>
          <li><a href="#" class="quicklink">FAQ</a></li>
        </ul>
        </div>
      </div>
    `;
  }
  
}
