import { Controller } from "@hotwired/stimulus";
import EditorJS from "@editorjs/editorjs";
import LinkTool from "@editorjs/link";

// These are the plugins
import CodeTool from "@editorjs/code";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import AttachesTool from "@editorjs/attaches";
import Embed from "@editorjs/embed";
import Marker from "@editorjs/marker";
// import DragDrop from "editorjs-drag-drop";

const AlignmentTuneTool = require("editorjs-text-alignment-blocktune");

// Connects to data-controller="editor"
export default class extends Controller {
  static targets = ["post_content"];

  csrfToken() {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    console.log(metaTag);
    return metaTag ? metaTag.content : "";
  }
  connect() {
    console.log("Hello, Stimulus!", this.element);

    const initialContent = this.getInitialContent();

    this.contentEditor = new EditorJS({
      // Holder is the target element
      placeholder: "Let`s write an awesome story!",
      // readOnly: true,
      holder: this.post_contentTarget,
      data: initialContent,
      // onReady: () => {
      //   new DragDrop(this.contentEditor);
      // },
      tools: {
        header: {
          class: Header,
          tunes: ["anyTuneName"],
        },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: "/api/fetch_url",
            headers: {
              "X-CSRF-Token": this.csrfToken(),
            },
          },
        },
        attaches: {
          class: AttachesTool,
          config: {
            endpoint: "/posts/upload_file",
            additionalRequestHeaders: {
              "X-CSRF-Token": this.csrfToken(),
            },
          },
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
          config: {
            services: {
              youtube: true,
              coub: true,
              instagram: true,
              twitter: true,
              github: true,
              pinterest: true,
              imgur: true,
              vimeo: true,
              facebook: true,
            },
          },
        },
        list: {
          class: List,
        },
        paragraph: {
          class: Paragraph,
          tunes: ["anyTuneName"],
          config: {
            inlineToolbar: true,
          },
        },
        anyTuneName: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: "center",
              list: "right",
            },
          },
        },
        code: CodeTool,
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: `/posts/upload_image`,
            },
            additionalRequestHeaders: {
              "X-CSRF-Token": this.csrfToken(),
            },
          },
        },
      },
    });

    this.element.addEventListener("submit", this.saveEditorData.bind(this));
  }

  getInitialContent() {
    const hiddenContentField = document.getElementById("post_content_hidden");
    if (hiddenContentField && hiddenContentField.value) {
      return JSON.parse(hiddenContentField.value);
    }
    return {};
  }

  async saveEditorData(event) {
    // event.preventDefault();

    const outputData = await this.contentEditor.save();

    const postForm = this.element;
    const fileInput = postForm.querySelector('input[name="post[thumbnail]"]');

    const hiddenInput = document.getElementById("post_content_hidden");

    hiddenInput.value = JSON.stringify(outputData);
    postForm.submit();
  }
}
