import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]

  connect() {
    this.hide()
    setTimeout(() => {
      this.show()
    }, 100)

    // Auto-hide after 5 seconds
    setTimeout(() => {
      this.hide()
    }, 5000)
  }

  show() {
    this.element.classList.remove("opacity-0", "-translate-y-4")
    this.element.classList.add("opacity-100", "translate-y-0")
  }

  hide() {
    this.element.classList.add("opacity-0", "-translate-y-4")
    this.element.classList.remove("opacity-100", "translate-y-0")
  }
}